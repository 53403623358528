// noinspection ES6UnusedImports
import MessageBroker from '@bonprix/pattern-library/components/message-broker.js';
import Icon from '@bonprix/pattern-library/templates/icon';
import Messages from '@core/scripts/components/messages.js';
import fetchWithCSRF from '@core/scripts/helper/fetch-with-csrf';
import React from 'react';
import {render} from 'react-dom';
import PrivacyCenterLayer from '../components/privacy-center';
import JshModule from "@core/scripts/helper/jsh-module";

const {moduleScope, config} = JshModule('cookie-banner');

const bannerNode = moduleScope;
const declineElement = moduleScope.querySelector('[jsh-cookie-decline]');
const acceptElement = moduleScope.querySelector('[jsh-cookie-accept]');
const iconElement = moduleScope.querySelector('[jsh-cookies-icon]');
let privacyElement;

render(<Icon name={'cookies'}/>, iconElement);

document.body.style.marginBottom = moduleScope.offsetHeight + 'px';

declineElement.addEventListener('click', () => {
    bannerNode.classList.remove('is-opened');
    document.body.style.marginBottom = '0';
    fetchWithCSRF(config.cookieDeclineUrl, {
        method: 'POST', body: config.pageViewId,
    });
    sendOptInMessageToApp(config.optInForApp, false, config.OS);
});
acceptElement.addEventListener('click', () => {
    bannerNode.classList.remove('is-opened');
    document.body.style.marginBottom = '0';
    fetchWithCSRF(config.cookieAcceptUrl, {
        method: 'POST', body: config.pageViewId,
    });
    MessageBroker.publish(Messages.OPTIN.MARKETING_STATUS_CHANGED, {optIn: true});
    MessageBroker.publish(Messages.OPTIN.ANALYTICS_STATUS_CHANGED, {optIn: true});
    MessageBroker.publish(Messages.OPTIN.COMPLETE_CONSENT_GIVEN);
    sendOptInMessageToApp(config.optInForApp, true, config.OS);
});
if (config.privacyCenterDataUrl !== undefined && config.privacyCenterDataUrl.trim() !== '') {

    privacyElement = moduleScope.querySelector('[jsh-cookie-privacy]');
    privacyElement.addEventListener('click', openPrivacyCenterLayer);
}

function openPrivacyCenterLayer() {
    const privacyCenterLayerNode = document.createElement('div');
    privacyCenterLayerNode.setAttribute('jsh-privacy-center-layer', '');
    document.body.appendChild(privacyCenterLayerNode);

    render(<PrivacyCenterLayer centerDataUrl={config.privacyCenterDataUrl}
                               pageViewId={config.pageViewId}/>, privacyCenterLayerNode);
}

function sendOptInMessageToApp(optInForApp, isOptIn, androidOrIos) {
    if (optInForApp === 'true') {
        if (androidOrIos === 'android') {
            BonprixMobileApp.userTrackingConsentChanged(isOptIn);
        } else if (androidOrIos === 'ios') {
            const content = window.webkit.messageHandlers.userTrackingConsentChanged;
            content.postMessage(isOptIn);
        }
    }
}
